import React from 'react'

class Review extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="review mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2 mb-15">Our Recognitions and Rewards
              </h2>
              <p className="pharagraph head">Witness our accomplishments as a global pioneer in the crypto industry as we have received a cluster of awards & recognition for our uncompromising efforts.
              </p>
            </div>
          </div>
          <div className="row mb-25">
            <div className="col-lg-3 col-md-3 col-sm-12 text-center mmb20">
              <a href='https://www.topdevelopers.co/profile/coinsclone' rel="noreferrer noopener" target="_blank">
                <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/top-developer.png"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/top-developer.png 150w,
                https://coinsclone.mo.cloudinary.net/images/top-developer.png 120w,"
                className="lazyload  topdev"
                alt="shortlst" />
              </a>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center mmb20 d-flex align-items-center justify-content-center">
              <a href='https://www.sortlist.com/agency/coinsclone' rel="noreferrer noopener" target="_blank">
                <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/shortlist.svg"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/shortlist.svg 150w,
                https://coinsclone.mo.cloudinary.net/images/shortlist.svg 120w,"
                className="lazyload  Shortlist"
                alt="shortlst" />
              </a>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center d-flex align-items-center justify-content-center mmb20">
              <a href='https://www.designrush.com/agency/profile/coinsclone' rel="noreferrer noopener" target="_blank" >
                <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/top-blockchain.webp"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/top-blockchain.webp 150w,
                https://coinsclone.mo.cloudinary.net/images/top-blockchain.webp 120w,"
                className="lazyload  topblck"
                alt="Top Outsource" />
              </a>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center d-flex align-items-center justify-content-center">
              <a href='https://sourceforge.net/software/product/Coinsclone/' rel="noreferrer noopener" target="_blank" >
                <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/sourceforge.svg"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/sourceforge.svg 150w,
                https://coinsclone.mo.cloudinary.net/images/sourceforge.svg 120w,"
                className="lazyload  sourceforge"
                alt="sourceforge" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 text-center mmb20">
            <a href='https://www.goodfirms.co/company/coinsclone' rel="noreferrer noopener" target="_blank" className='good-firm' >
              <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/home/good-firm.svg"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/home/good-firm.svg 150w,
                https://coinsclone.mo.cloudinary.net/images/home/good-firm.svg 120w,"
                className="lazyload  gdfrm"
                alt="Goodfirms" />
                </a>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center mmb20">
              <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/home/Softwareworld.png"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/home/Softwareworld.png 150w,
                https://coinsclone.mo.cloudinary.net/images/home/Softwareworld.png 120w,"
                className="lazyload  softwrld"
                alt="Softwareworld" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center mmb20">
              <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/home/Clutch.png"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/home/Clutch.png 150w,
                https://coinsclone.mo.cloudinary.net/images/home/Clutch.png 120w,"
                className="lazyload  clutch"
                alt="Clutch" />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 text-center d-flex align-items-center justify-content-center">
              <a href='https://slashdot.org/software/p/Coinsclone/' rel="noreferrer noopener" target="_blank" className='slashot' >
                <img
                rel="preload" fetchpriority="high" as="image"
                data-sizes="(min-width: 800px) 135px"
                data-src="https://coinsclone.mo.cloudinary.net/images/slashdot.svg"
                data-srcset="https://coinsclone.mo.cloudinary.net/images/slashdot.svg 150w,
                https://coinsclone.mo.cloudinary.net/images/slashdot.svg 120w,"
                className="lazyload  slashdot"
                alt="Slashdot" />
              </a>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Review