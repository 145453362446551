import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span>
               Of our NFT Lending Platform Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Advanced Search Feature</h4>
                <p className="pharagraph">The users can select the right kind of loan by using the modern search option. They can choose various NFT Marketplaces (listed and unlisted) according to their preference.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Advanced-Search-Feature.png" alt="Advanced Search Feature" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Great-Market-Visibility.png" alt="Great Market Visibility" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Great Market Visibility</h4>
                <p className="pharagraph">The growth of an NFT lending platform completely depends on the audience, the high increase in the usage of the domain, increases the value and growth of lending and valuing of NFTs. Our lending platform provides a very high-level market visibility for business models and allows users to experience a hassle-free experience.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Ownership</h4>
                <p className="pharagraph">Ownership is a unique characteristic among other features. Unlike the other fungible tokens, NFTs can only be transferred or transacted by their owners because of the unique smart contracts and rights associated with them.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Ownership.png" alt="Ownership" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Staking-of-Crypto-Collectibles.png" alt="Staking of Crypto Collectibles" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Staking of Crypto Collectibles</h4>
                <p className="pharagraph">Many NFT users have digital assets in their wallets that are not used unless they use them to interact with a specific platform. So, rather than sitting idle in the marketplace users can lend their assets to others and earn money.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Flexible Financing Options</h4>
                <p className="pharagraph">An NFT lending and borrowing platform is a great idea because it allows users to obtain loans by lending their digital assets as collateral to other users within the platform. The arrival of NFT lending increases passive income for business entities and executes more accurate valuations of digital assets.</p>
                <div className="text-left">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Flexible-Financing-Options.png" alt="Flexible Financing Options" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures