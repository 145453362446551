import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
                NFT Lending Platform Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/usecase.svg" alt="Use Case of NFT Lending Platform Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Real Estate Lending -</b> Real estate assets all over the world can be converted into NFTs and then can be used as collateral for taking loans.
              </p>
              <p className="pharagraph" ><b>Art Lending  -</b> Pieces of digital art, after converting into NFTs, can be used as collateral for raising funds and liquidity.
              </p>
              <p className="pharagraph" ><b>Banking Loans  -</b>  It becomes easier for the banks to issue loans because they are based on smart contracts that are inviolable.
              </p>
              <p className="pharagraph" ><b>Peer-To-Peer Loans  -</b>   Funds can be raised directly between individuals through the tightly knitted contracts embedded into the NFTs.
              </p>
              <p className="pharagraph" ><b>Copyright Lending  -</b> Copyrights, after converting them into NFTs, can be loaned to other artists for temporary use, in exchange of liquidity.
              </p>
              <p className="pharagraph" ><b>Collectibles As Collateral  -</b> Precious collectibles can be used as collateral to avail instant loans as well as long-term liquidity.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf